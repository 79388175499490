import Icon4gBackup from "@/components/icon/4gBackup.vue";
import IconBriefcase from "@/components/icon/Briefcase.vue";
import IconCarbon from "@/components/icon/Carbon.vue";
import IconCloud from "@/components/icon/Cloud.vue";
import IconConstruction from "@/components/icon/Construction.vue";
import IconCustomers from "@/components/icon/Customers.vue";
import IconDigitalSense from "@/components/icon/DigitalSense.vue";
import IconEducation from "@/components/icon/Education.vue";
import IconFibre from "@/components/icon/Fibre.vue";
import IconFinance from "@/components/icon/Finance.vue";
import IconGlobe from "@/components/icon/Globe.vue";
import IconHealth from "@/components/icon/Health.vue";
import IconHelp from "@/components/icon/Help.vue";
import IconHostedPhones from "@/components/icon/HostedPhones.vue";
import IconHouse from "@/components/icon/House.vue";
import IconLegacy from "@/components/icon/Legacy.vue";
import IconLocalGovernment from "@/components/icon/LocalGovernment.vue";
import IconMail from "@/components/icon/Mail.vue";
import IconMessage from "@/components/icon/Message.vue";
import IconMobile from "@/components/icon/Mobile.vue";
import IconMobileData from "@/components/icon/MobileData.vue";
import IconModem from "@/components/icon/Modem.vue";
import IconMotif from "@/components/icon/Motif.vue";
import IconMyPhone from "@/components/icon/MyPhone.vue";
import IconOutage from "@/components/icon/Outage.vue";
import IconOverTheWire from "@/components/icon/OverTheWire.vue";
import IconPhone from "@/components/icon/Phone.vue";
import IconProfServices from "@/components/icon/ProfServices.vue";
import IconReferral from "@/components/icon/Referral.vue";
import IconRetail from "@/components/icon/Retail.vue";
import IconSecure from "@/components/icon/Secure.vue";
import IconSim from "@/components/icon/Sim.vue";
import IconSipTrunks from "@/components/icon/SipTrunks.vue";
import IconSpanner from "@/components/icon/Spanner.vue";
import IconSpeed from "@/components/icon/SpeedTest.vue";
import IconSolution from "@/components/icon/Solution.vue";
import IconWifi from "@/components/icon/Wifi.vue";
import IconWhiteLabel from "@/components/icon/WhiteLabel.vue";
import IconWholesale from "@/components/icon/Wholesale.vue";
import IconData from "@/components/icon/Data.vue";
import IconNetwork from "@/components/icon/Network.vue";
import IconCustomerPhone from "@/components/icon/CustomerPhone.vue";

import type { Segment } from "@/types";

export interface NavChild {
  name: string;
  id: string;
  icon: Component | string;
  link?: string;
  content?: {
    heading: string;
    title: string;
    description: string;
    ctaTitle: string;
    ctaLink: string;
    proCta?: boolean;
    children: {
      title: string;
      cols: 1 | 2;
      rows: 1 | 2 | 3 | 4;
      links: {
        name: string;
        link: string;
        desc: string;
        icon: Component;
      }[];
    }[];
    quickLinks?: {
      title: string;
      links: {
        name: string;
        link: string;
        pro?: boolean;
      }[];
      cta?: {
        name: string;
        link: string;
      };
    };
  };
}

export interface NavData {
  name: string;
  nameAbbr?: string;
  link: string;
  children: NavChild[];
}

export function getNavData(): {
  segmentData: Record<Segment, NavData>;
  loginData: NavChild;
} {
  return {
    segmentData: {
      personal: {
        name: "Personal",
        link: "https://www.aussiebroadband.com.au/",
        children: [
          {
            name: "Internet and Phone",
            id: "personalInternet",
            icon: IconGlobe,
            content: {
              heading: "Personal",
              title: "Internet and phone for your home.",
              description:
                "<p class='text-sm'>Join the award-winning network with a 100% Australian-based support team, unlimited data, and no lock-in contracts.</p>",
              ctaTitle: "",
              ctaLink: "",
              proCta: true,
              children: [
                {
                  title: "Internet",
                  cols: 1,
                  rows: 2,
                  links: [
                    {
                      name: "nbn® Plans",
                      link: "https://www.aussiebroadband.com.au/internet/nbn-plans/",
                      desc: "Fast and reliable internet on the nbn® network.",
                      icon: IconGlobe,
                    },
                    {
                      name: "OptiComm Plans",
                      link: "https://www.aussiebroadband.com.au/internet/opticomm-plans/",
                      desc: "Find out if OptiComm is available to you.",
                      icon: IconFibre,
                    },
                  ],
                },
                {
                  title: "Add-ons",
                  cols: 1,
                  rows: 2,
                  links: [
                    {
                      name: "Modems and Hardware",
                      link: "/internet/hardware/",
                      desc: "nbn® compatible modem routers and phone adapters.",
                      icon: IconModem,
                    },
                    {
                      name: "Home Phone",
                      link: "/internet/home-phone/",
                      desc: "Keep your home phone when moving across to the nbn®.",
                      icon: IconPhone,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Quick Links",
                links: [
                  {
                    name: "Internet for Gamers",
                    link: "/internet/gamers-nbn/",
                  },
                  {
                    name: "Internet for Seniors",
                    link: "/internet/seniors-nbn/",
                  },
                  {
                    name: "nbn® $0 FTTP upgrade",
                    link: "https://www.aussiebroadband.com.au/internet/nbn-fttp-upgrade/",
                  },
                  {
                    name: "High upload nbn®",
                    link: "https://www.aussiebroadband.com.au/internet/ultrafast-nbn/",
                    pro: true,
                  },
                ],
              },
            },
          },
          {
            name: "Mobile",
            id: "personalMobile",
            icon: IconMobile,
            content: {
              heading: "Personal",
              title: "Awe-SIM mobile plans.",
              description:
                "<p class='text-sm'>With reliable coverage and the speeds you need, we're got you covered with 5G, or data-only options, powered by the Optus Network.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Mobile",
                  cols: 2,
                  rows: 1,
                  links: [
                    {
                      name: "Mobile SIM Plans",
                      link: "/mobile/sim-only-plans/",
                      desc: "Enjoy fast speeds and flexibility on our 5G mobile SIM-only plans.",
                      icon: IconSim,
                    },
                    {
                      name: "Mobile Broadband",
                      link: "/mobile/data-only-sim/",
                      desc: "Fast and reliable internet at home and on the move with data-only SIMs.",
                      icon: IconMobileData,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Activate my mobile SIM",
                    link: "https://www.aussiebroadband.com.au/tools/activate-sim/",
                  },
                  {
                    name: "Upgrade my plan",
                    link: "/help-centre/mobile/can-i-upgrade-my-plan-mid-month/",
                  },
                  {
                    name: "View the coverage map",
                    link: "/network/mobile-coverage-map/",
                  },
                ],
              },
            },
          },
          {
            name: "Help and Support",
            id: "personalHelp",
            icon: IconHelp,
            content: {
              heading: "Personal",
              title: "get help and solve problems.",
              description:
                "<p class='text-sm'>Got an issue with an Aussie Broadband product? Have a question about one of our services? Browse our Help Centre to find an answer.</p>",
              ctaTitle: "",
              ctaLink: "/help-centre/",
              children: [
                {
                  title: "Support",
                  cols: 1,
                  rows: 4,
                  links: [
                    {
                      name: "Help Centre",
                      link: "/help-centre/",
                      desc: "Browse our in-depth setup and troubleshooting guides.",
                      icon: IconHelp,
                    },
                    {
                      name: "Contact us",
                      link: "/contact/",
                      desc: "Need to get in touch? Drop us a line here.",
                      icon: IconMessage,
                    },
                    {
                      name: "Outages",
                      link: "/network/outages/",
                      desc: "Check our current and scheduled outages here.",
                      icon: IconOutage,
                    },
                    {
                      name: "Moving Home",
                      link: "/moving-home/",
                      desc: "Take your internet with you when you move home.",
                      icon: IconHouse,
                    },
                  ],
                },
                {
                  title: "Tools",
                  cols: 1,
                  rows: 3,
                  links: [
                    {
                      name: "Speed Test",
                      link: "https://www.aussiebroadband.com.au/speed-test/",
                      desc: "Feel the need? Check your speed with our Speed Test tool.",
                      icon: IconSpeed,
                    },
                    {
                      name: "Network Centre",
                      link: "https://www.aussiebroadband.com.au/network/",
                      desc: "View info on our CVC, POIs, Coverage Maps, and more.",
                      icon: IconWifi,
                    },
                    {
                      name: "MyAussie®",
                      link: "https://my.aussiebroadband.com.au/",
                      desc: "Access your MyAussie® account.",
                      icon: IconMotif,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Popular topics",
                links: [
                  {
                    name: "How to set up your modem",
                    link: "/help-centre/internet/byo-router-settings-nbn-guide/",
                  },
                  {
                    name: "Working from home",
                    link: "/help-centre/internet/working-from-home-faq/",
                  },
                  {
                    name: "General email settings",
                    link: "/help-centre/internet/general-email-settings/",
                  },
                  {
                    name: "No internet connection?",
                    link: "/help-centre/internet/no-internet-connection/",
                  },
                ],
              },
            },
          },
        ],
      },
      business: {
        name: "Business",
        link: "/business/",
        children: [
          {
            name: "Internet",
            id: "businessInternet",
            icon: IconGlobe,
            content: {
              heading: "Business",
              title: "Fast, reliable internet for your business.",
              description:
                "<p class='text-sm'>Choose from our range of great-value internet services to suit all connection types.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Internet",
                  cols: 1,
                  rows: 3,
                  links: [
                    {
                      name: "Business nbn®",
                      link: "https://www.aussiebroadband.com.au/business/internet/nbn-plans/",
                      desc: "Fast, reliable nbn®. Customisable to suit your business.",
                      icon: IconGlobe,
                    },
                    // {
                    //   name: "Business OptiComm",
                    //   link: "https://www.aussiebroadband.com.au/business/internet/opticomm/",
                    //   desc: "Fast, reliable internet on the Opticomm network.",
                    //   icon: IconFibre,
                    // },
                    {
                      name: "nbn® Enterprise Ethernet",
                      link: "https://www.aussiebroadband.com.au/business/internet/enterprise-ethernet/",
                      desc: "Future-proof your business with fast fibre on the nbn®.",
                      icon: IconFibre,
                    },
                    {
                      name: "Aussie Fibre",
                      link: "/business/internet/aussie-fibre/",
                      desc: "Access ultrafast, future-ready fibre plans up to 10Gbps.",
                      icon: IconSpeed,
                    },
                  ],
                },
                {
                  title: "Network",
                  cols: 1,
                  rows: 3,
                  links: [
                    {
                      name: "Business Hardware",
                      link: "/business/internet/network-devices/",
                      desc: "Everything you need to keep your connection running smoothly.",
                      icon: IconModem,
                    },
                    {
                      name: "Security",
                      link: "https://www.aussiebroadband.com.au/business/internet/fortinet/",
                      desc: "Protect your business and its assets from cyber threats.",
                      icon: IconSecure,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Quick Links",
                links: [
                  {
                    name: "nbn® $0 FTTP Upgrade",
                    link: "https://www.aussiebroadband.com.au/business/internet/nbn-fttp-upgrade/",
                  },
                  {
                    name: "Netcomm NL1901ACV setup guide",
                    link: "/help-centre/internet/netcomm-nl1901acv-setup-guide/",
                  },
                  {
                    name: "What can affect nbn® speeds?",
                    link: "/help-centre/internet/what-factors-can-affect-the-speed-of-my-nbn-internet-connection/",
                  },
                  {
                    name: "Run an internet speed test",
                    link: "/help-centre/internet/how-do-i-run-a-speed-test/",
                  },
                ],
              },
            },
          },
          {
            name: "Mobile",
            id: "businessMobile",
            icon: IconMobile,
            content: {
              heading: "Business",
              title: "Fast 5G with 98.5% coverage.",
              description:
                "<p class='text-sm'>Share data with your team and easily manage your mobile services on the MyAussie® app.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Mobile",
                  cols: 2,
                  rows: 2,
                  links: [
                    {
                      name: "Mobile SIM Plans",
                      link: "/business/mobile/sim-plans",
                      desc: "Connect your business with great value 5G plans.",
                      icon: IconSim,
                    },
                    {
                      name: "Mobile Broadband",
                      link: "/business/mobile/mobile-broadband/",
                      desc: "Power your devices on the go with reliable 5G data.",
                      icon: IconMobileData,
                    },
                    {
                      name: "4G Backup",
                      link: "/business/mobile/4g-backup/",
                      desc: "Protect your business from unexpected internet outages.",
                      icon: Icon4gBackup,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Activate my mobile SIM",
                    link: "https://www.aussiebroadband.com.au/tools/activate-sim/",
                  },
                  {
                    name: "Upgrade my plan",
                    link: "/help-centre/mobile/can-i-upgrade-my-plan-mid-month/",
                  },
                  {
                    name: "View the coverage map",
                    link: "/network/mobile-coverage-map/",
                  },
                ],
              },
            },
          },
          {
            name: "Voice",
            id: "businessVoice",
            icon: IconPhone,
            content: {
              heading: "Business",
              title: "Connect to the new standard in phone technology.",
              description:
                "<p class='text-sm'>Enjoy advanced features and save on call costs with a hosted phone system built for modern business needs.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Voice",
                  cols: 2,
                  rows: 2,
                  links: [
                    {
                      name: "Phone Systems",
                      link: "https://www.aussiebroadband.com.au/business/voice/hosted-pbx/",
                      desc: "A full-service phone system, hosted and managed in the cloud.",
                      icon: IconHostedPhones,
                    },
                    {
                      name: "VoIP Phone",
                      link: "https://www.aussiebroadband.com.au/business/voice/voip-phone",
                      desc: "A simple, single-line connection to your business.",
                      icon: IconPhone,
                    },
                    {
                      name: "SIP Trunks",
                      link: "https://www.aussiebroadband.com.au/business/voice/sip-trunks/",
                      desc: "Connect your existing phone system to the internet.",
                      icon: IconSipTrunks,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Quick links",
                links: [
                  {
                    name: "The key features of hosted PBX",
                    link: "/help-centre/phone/what-are-the-key-features-of-your-hosted-pbx-pabx-solution/",
                  },
                  {
                    name: "Set up custom call queues",
                    link: "/help-centre/phone/call-queues-create-intro-greeting-for-a-call-queue/",
                  },
                  {
                    name: "Access your voicemail remotely",
                    link: "/help-centre/phone/hosted-pbx-voicemail-access-voicemail-remotely/",
                  },
                  {
                    name: "Call routing best practices",
                    link: "/help-centre/phone/call-routing-best-practices/",
                  },
                ],
              },
            },
          },
          {
            name: "Industries",
            id: "businessIndustries",
            icon: IconBriefcase,
            content: {
              heading: "Business",
              title: "Custom solutions for all industries.",
              description:
                "<p class='text-sm'>We're proud to offer custom telco solutions to a wide range of industries. Check out our industry-specific solutions and see what's possible in your business.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Industries we service",
                  cols: 2,
                  rows: 3,
                  links: [
                    {
                      name: "Healthcare",
                      link: "https://www.aussiebroadband.com.au/business/healthcare/",
                      desc: "Keep critical connectivity covered with solutions designed for healthcare.",
                      icon: IconHealth,
                    },
                    {
                      name: "Retail",
                      link: "https://www.aussiebroadband.com.au/business/retail/",
                      desc: "Power your sales with connectivity designed for retail.",
                      icon: IconFibre,
                    },
                    {
                      name: "Finance",
                      link: "https://www.aussiebroadband.com.au/business/finance/",
                      desc: "Solutions you can count on.",
                      icon: IconFinance,
                    },
                    {
                      name: "Construction",
                      link: "https://www.aussiebroadband.com.au/business/construction/",
                      desc: "Stay connected, wherever your jobs take you.",
                      icon: IconConstruction,
                    },
                    {
                      name: "Professional Services",
                      link: "https://www.aussiebroadband.com.au/business/professional-services/",
                      desc: "Thrive in the digital-first business world.",
                      icon: IconProfServices,
                    },
                    {
                      name: "Education",
                      link: "https://www.aussiebroadband.com.au/business/education/",
                      desc: "The tech you need to deliver world-class, modern learning.",
                      icon: IconEducation,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Case Studies",
                links: [
                  {
                    name: "Foodworks",
                    link: "/case-studies/foodworks/",
                  },
                  {
                    name: "Nova",
                    link: "/case-studies/nova/",
                  },
                  {
                    name: "National Storage",
                    link: "/case-studies/national-storage/",
                  },
                  {
                    name: "James Cook University",
                    link: "/case-studies/james-cook-uni/",
                  },
                  {
                    name: "Cumulus Visual Effects",
                    link: "/case-studies/cumulus/",
                  },
                  {
                    name: "Shire of Mundaring",
                    link: "/case-studies/mundaring-shire/",
                  },
                ],
                cta: {
                  name: "View all case studies",
                  link: "/case-studies/",
                },
              },
            },
          },
          {
            name: "Help and Support",
            id: "businessHelp",
            icon: IconHelp,
            content: {
              heading: "Business",
              title: "Whatever your business needs, we're here to help",
              description:
                "<p class='text-sm'>Check out our handy guides and self-service tools. And our friendly, Australian-smd team of business telco experts are a phone call away.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Support",
                  cols: 1,
                  rows: 3,
                  links: [
                    {
                      name: "Help Centre",
                      link: "/help-centre/tags/business/",
                      desc: "Browse our in-depth setup and troubleshooting guides.",
                      icon: IconHelp,
                    },
                    {
                      name: "Contact Us",
                      link: "/business/contact/",
                      desc: "Got a question? Need support? Get in touch here.",
                      icon: IconMessage,
                    },
                    {
                      name: "Outages",
                      link: "/network/outages/",
                      desc: "Check our current and scheduled outages here.",
                      icon: IconOutage,
                    },
                  ],
                },
                {
                  title: "Tools",
                  cols: 1,
                  rows: 4,
                  links: [
                    {
                      name: "Network Centre",
                      link: "https://www.aussiebroadband.com.au/network/",
                      desc: "Check network performance, see our upgrade schedule and more.",
                      icon: IconWifi,
                    },
                    {
                      name: "MyAussie®",
                      link: "https://my.aussiebroadband.com.au/",
                      desc: "Manage your Aussie Broadband services.",
                      icon: IconMotif,
                    },
                    {
                      name: "Carbon",
                      link: "https://carbon.aussiebroadband.com.au",
                      desc: "Manage your network and Aussie Broadband services.",
                      icon: IconCarbon,
                    },
                    {
                      name: "Speed Test",
                      link: "https://www.aussiebroadband.com.au/speed-test/",
                      desc: "Find out how your business's connection is performing.",
                      icon: IconSpeed,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Popular topics",
                links: [
                  {
                    name: "What level support should I get?",
                    link: "/help-centre/internet/do-i-need-a-bronze-silver-or-gold-support-package-with-my-nbn-service/",
                  },
                  {
                    name: "What's an eSLA?",
                    link: "/help-centre/internet/what-is-an-esla/",
                  },
                  {
                    name: "How to switch business nbn® providers",
                    link: "/blog/how-to-switch-business-nbn-providers/",
                  },
                  {
                    name: "Cyber security tips for small businesses",
                    link: "/blog/cyber-security-tips-for-small-businesses/",
                  },
                ],
              },
            },
          },
        ],
      },
      enterprise: {
        name: "Enterprise and Government",
        nameAbbr: "Enterprise and Gov",
        link: "/enterprise/",
        children: [
          {
            name: "by Product",
            id: "enterpriseByProduct",
            icon: IconGlobe,
            content: {
              heading: "Enterprise",
              title: "Personalised Enterprise product solutions.",
              description:
                "<p class='text-sm !mb-4'>Our Enterprise solutions are perfect if your company:</p><ul class='!mb-4'><li class='!text-sm'>Has more than 100 staff</li><li class='!text-sm'>Has multiple sites</li><li class='!text-sm'>Requires a complex and personalised solution</li></ul><p class='text-sm !mb-8'>Doesn't sound like you? Check out our off-the-shelf Business products:</p>",
              ctaTitle: "Browse our Business products",
              ctaLink: "/business/",
              children: [
                {
                  title: "Solutions",
                  cols: 2,
                  rows: 3,
                  links: [
                    {
                      name: "Internet",
                      link: "/enterprise/internet/",
                      desc: "Solutions to get your Enterprise connected, from nbn® to Enterprise Ethernet.",
                      icon: IconGlobe,
                    },
                    {
                      name: "Mobile and Voice",
                      link: "/enterprise/mobile-voice/",
                      desc: "If you need a fleet of SIM plans, or a business phone solution, we have a solution for you.",
                      icon: IconPhone,
                    },
                    {
                      name: "Managed Network",
                      link: "/enterprise/network/",
                      desc: "SD-WAN, Hosted Firewall, Private IP and more.",
                      icon: IconWifi,
                    },
                    {
                      name: "Managed Security",
                      link: "/enterprise/security/",
                      desc: "Don't be the next headline - leave your security in trusted hands.",
                      icon: IconSecure,
                    },
                    {
                      name: "Managed Cloud",
                      link: "/enterprise/cloud/",
                      desc: "Need to move your data into the cloud? We have a range of solutions.",
                      icon: IconCloud,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Upgrade to Enterprise Ethernet",
                    link: "/enterprise/internet/enterprise-ethernet/",
                  },
                  {
                    name: "Upgrade to Aussie Fibre",
                    link: "/business/internet/aussie-fibre/",
                  },
                  {
                    name: "Set up a Business Phone System",
                    link: "https://www.aussiebroadband.com.au/business/voice/hosted-pbx/",
                  },
                  {
                    name: "Easily manage my services with Carbon",
                    link: "/enterprise/carbon/",
                  },
                  {
                    name: "Set up a SASE (Secure Access Service Edge)",
                    link: "/enterprise/security/sase/",
                  },
                ],
              },
            },
          },
          {
            name: "by Need",
            id: "enterpriseByNeed",
            icon: IconReferral,
            content: {
              heading: "Enterprise",
              title: "Solutions to common enterprise issues.",
              description:
                "<p class='text-sm !mb-4'>Our Enterprise solutions are perfect if your company:</p><ul class='!mb-4'><li class='!text-sm'>Has more than 100 staff</li><li class='!text-sm'>Has multiple sites</li><li class='!text-sm'>Requires a complex and personalised solution</li></ul><p class='text-sm !mb-8'>Doesn't sound like you? Check out our off-the-shelf Business products:</p>",
              ctaTitle: "Browse our Business products",
              ctaLink: "/business/",
              children: [
                {
                  title: "Solutions",
                  cols: 2,
                  rows: 3,
                  links: [
                    {
                      name: "Improve Network Performance",
                      link: "/enterprise/network-performance/",
                      desc: "Is your network no longer keeping pace with your growing Enterprise needs?",
                      icon: IconSpeed,
                    },
                    {
                      name: "Reduce IT Complexity",
                      link: "/enterprise/it-complexity/",
                      desc: "Have you fallen into the trap of an overly complex IT environment?",
                      icon: IconSolution,
                    },
                    {
                      name: "Upgrade Outdated Tech",
                      link: "/enterprise/upgrade-tech/",
                      desc: "Antiquated and legacy technology can slow you down, and present a big security risk.",
                      icon: IconLegacy,
                    },
                    {
                      name: "Retain Skilled Tech Staff",
                      link: "/enterprise/staff-retention/",
                      desc: "If you want to keep your best tech workers, you need to make sure they are taken care of.",
                      icon: IconSpanner,
                    },
                    {
                      name: "Protect Customer Data",
                      link: "/enterprise/data-security/",
                      desc: "Unlock the keys to safeguarding your customers and organisation.",
                      icon: IconCustomers,
                    },
                    {
                      name: "Protect Company Assets",
                      link: "/enterprise/company-security/",
                      desc: "Where does IT rank in your risk management hierarchy?",
                      icon: IconSecure,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Switch to SD-WAN",
                    link: "/enterprise/network/sd-wan/",
                  },
                  {
                    name: "Set up enterprise-grade firewall",
                    link: "/enterprise/network/hosted-firewall/",
                  },
                  {
                    name: "Migrate to cloud storage",
                    link: "/enterprise/cloud/",
                  },
                  {
                    name: "Set up private IP network",
                    link: "/enterprise/network/private-ip/",
                  },
                  {
                    name: "Easily manage my services with Carbon",
                    link: "/enterprise/carbon/",
                  },
                ],
              },
            },
          },
          {
            name: "by Industry",
            id: "enterpriseByIndustry",
            icon: IconBriefcase,
            content: {
              heading: "Enterprise",
              title: "Custom solutions for all industries.",
              description:
                "<p class='text-sm'>We've worked with Enterprise businesses across a huge range of industries, so you can rest assured there is a solution for you.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Solutions",
                  cols: 2,
                  rows: 4,
                  links: [
                    {
                      name: "Healthcare",
                      link: "/enterprise/healthcare/",
                      desc: "Hospitals, medical clinics, and other healthcare providers.",
                      icon: IconHealth,
                    },
                    {
                      name: "Education",
                      link: "/enterprise/education/",
                      desc: "Private and public schools, and other educational institutions.",
                      icon: IconEducation,
                    },
                    {
                      name: "Finance",
                      link: "/enterprise/finance/",
                      desc: "Banks, lenders, accountants and other financial institutions.",
                      icon: IconFinance,
                    },
                    {
                      name: "Construction",
                      link: "/enterprise/construction/",
                      desc: "Companies and contractors in the construction industry.",
                      icon: IconConstruction,
                    },
                    {
                      name: "Retail",
                      link: "/enterprise/retail/",
                      desc: "Shops, supermarkets, and eCommerce providers.",
                      icon: IconRetail,
                    },
                    {
                      name: "Local Government",
                      link: "/enterprise/local-government/",
                      desc: "Local councils, shires, and small government agencies.",
                      icon: IconLocalGovernment,
                    },
                    {
                      name: "IT Services",
                      link: "/enterprise/it/",
                      desc: "Companies that provide Enterprise-grade IT support.",
                      icon: IconModem,
                    },
                    {
                      name: "Professional Services",
                      link: "/enterprise/professional-services/",
                      desc: "Consultancies and organisations that provide specific services.",
                      icon: IconProfServices,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "Case studies",
                links: [
                  {
                    name: "Westpac",
                    link: "/case-studies/westpac/",
                  },
                  {
                    name: "Foodworks",
                    link: "/case-studies/foodworks/",
                  },
                  {
                    name: "Nova",
                    link: "/case-studies/nova/",
                  },
                  {
                    name: "National Storage",
                    link: "/case-studies/national-storage/",
                  },
                  {
                    name: "Mundaring Shire",
                    link: "/case-studies/mundaring-shire/",
                  },
                ],
                cta: {
                  name: "See all case studies",
                  link: "/case-studies/",
                },
              },
            },
          },
          {
            name: "Contact Us",
            id: "enterpriseContact",
            icon: IconMail,
            link: "/enterprise/contact/",
          },
        ],
      },
      wholesale: {
        name: "Wholesale",
        link: "/wholesale/",
        children: [
          {
            name: "by Channel",
            id: "wholesaleByChannel",
            icon: IconReferral,
            content: {
              heading: "Wholesale",
              title: "Solutions for every relationship.",
              description:
                "<p class='text-sm'>Partner with us and grow by taking advantage of our carrier-grade fibre and Tier 1 voice networks. Backed by our 100% Australian-based support experts.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Solutions",
                  cols: 2,
                  rows: 2,
                  links: [
                    {
                      name: "MSP Partner",
                      link: "/wholesale/msp/",
                      desc: "Offer more value with our fast and reliable network built for providers ready to grow.",
                      icon: IconModem,
                    },
                    {
                      name: "Partner Program",
                      link: "/wholesale/partner-program/",
                      desc: "Refer your customers to our award winning residential nbn® and mobile services.",
                      icon: IconReferral,
                    },
                    {
                      name: "Wholesale Carrier",
                      link: "/wholesale/carrier/",
                      desc: "Carrier-to-carrier network and voice from a wholesale partner ready to scale.",
                      icon: IconWholesale,
                    },
                    {
                      name: "White Label",
                      link: "/wholesale/white-label/",
                      desc: "Offer award-winning services under your brand and improve customer loyalty.",
                      icon: IconWhiteLabel,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Learn about the Aussie Network",
                    link: "/network/",
                  },
                  {
                    name: "Refer customers to Aussie Broadband",
                    link: "/wholesale/partner-program/",
                  },
                  {
                    name: "Package voice and data services for business customers",
                    link: "/wholesale/msp/",
                  },
                ],
              },
            },
          },
          {
            name: "by Product",
            id: "wholesaleByProduct",
            icon: IconGlobe,
            content: {
              heading: "Wholesale",
              title: "Flexible services for every need.",
              description:
                "<p class='text-sm'>Choose between easy, deliverable services for partners, or true Wholesale Carrier integration.</p>",
              ctaTitle: "",
              ctaLink: "",
              children: [
                {
                  title: "Solutions",
                  cols: 2,
                  rows: 3,
                  links: [
                    {
                      name: "Data",
                      link: "/wholesale/services/data/",
                      desc: "Fast and flexible broadband plans.",
                      icon: IconData,
                    },
                    {
                      name: "Aussie Fibre",
                      link: "/wholesale/services/aussie-fibre/",
                      desc: "Competitive on-net fibre to meet your needs.",
                      icon: IconFibre,
                    },
                    {
                      name: "Network",
                      link: "/wholesale/services/network-solutions/",
                      desc: "Build your network with ours.",
                      icon: IconNetwork,
                    },
                    {
                      name: "Voice Systems",
                      link: "/wholesale/services/voice/",
                      desc: "Hosted PBX, SIP Trunks, and Microsoft Teams Calling.",
                      icon: IconPhone,
                    },
                    {
                      name: "Voice Carrier",
                      link: "/wholesale/services/voice-carrier/",
                      desc: "Call termination, phone numbers, porting and more.",
                      icon: IconCustomerPhone,
                    },
                    {
                      name: "Carbon",
                      link: "/wholesale/carbon",
                      desc: "Learn more about our dedicated self-service portal.",
                      icon: IconCarbon,
                    },
                  ],
                },
              ],
              quickLinks: {
                title: "I want to",
                links: [
                  {
                    name: "Offer internet services to my business customers",
                    link: "/wholesale/services/data/",
                  },
                  {
                    name: "Help get my teams calling and productive.",
                    link: "/wholesale/services/voice/",
                  },
                ],
              },
            },
          },
          {
            name: "Become a Partner",
            id: "wholesalePartner",
            icon: IconCustomers,
            link: "/wholesale/become-a-partner/",
          },
        ],
      },
    },
    loginData: {
      name: "Login",
      id: "login",
      icon: IconReferral,
      content: {
        heading: "Login",
        title: "Find your Aussie Broadband Login.",
        description:
          "<p class='text-sm'>Login to our various Aussie Broadband accounts to manage your home, business, or enterprise.</p>",
        ctaTitle: "",
        ctaLink: "",
        children: [
          {
            title: "Personal",
            cols: 1,
            rows: 2,
            links: [
              {
                name: "MyAussie®",
                link: "https://my.aussiebroadband.com.au/",
                desc: "Manage your personal account - pay bills, add new services, and more.",
                icon: IconMotif,
              },
              {
                name: "Aussie Webmail",
                link: "https://webmail.aussiebroadband.com.au/",
                desc: "Access your Aussie Broadband webmail account.",
                icon: IconMail,
              },
            ],
          },
          {
            title: "Business",
            cols: 2,
            rows: 3,
            links: [
              {
                name: "MyAussie® for business",
                link: "https://my.aussiebroadband.com.au/",
                desc: "Manage your business account - pay bills, add new services, and more.",
                icon: IconMotif,
              },
              {
                name: "Carbon",
                link: "https://carbon.aussiebroadband.com.au/",
                desc: "Centrally manage your internet and network services across sites seamlessly online at any time.",
                icon: IconCarbon,
              },
              {
                name: "MyPhone",
                link: "https://myphone.aussiebroadband.com.au/",
                desc: "Self-manage your phone system including call routing, auto attendant features, and hunt groups.",
                icon: IconMyPhone,
              },
              {
                name: "FaktorTel",
                link: "https://portal.faktortel.com.au/login/",
                desc: "Manage your FaktorTel VoIP and Virtual PBX solution.",
                icon: IconPhone,
              },
              {
                name: "Digital Sense",
                link: "https://digitalsense.service-now.com/osp2",
                desc: "Customer service portal for all digital sense products.",
                icon: IconDigitalSense,
              },
              {
                name: "Over the Wire and NetSIP",
                link: "https://portal.overthewire.com.au/login",
                desc: "Self-manage your Over the Wire and Netsip products.",
                icon: IconOverTheWire,
              },
            ],
          },
        ],
      },
    },
  };
}
