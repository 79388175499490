<template>
  <footer class="w-full bg-grey-50">
    <section class="mx-auto mt-8 max-w-screen-xl">
      <div
        class="flex justify-between border-b border-grey-200 px-6 py-4 md:px-8"
      >
        <img
          class="h-auto w-32 md:w-40"
          src="/img/logos/logo-blk.svg"
          alt="Aussie Broadband logo"
          width="228"
          height="64"
        />
        <IconLogoBCorpLogo class="h-16 w-16" />
      </div>
      <div
        class="grid gap-x-6 gap-y-8 px-6 py-8 text-left sm:py-12 md:grid-cols-2 md:px-8 lg:grid-cols-4"
      >
        <div v-for="(el, index) in data" :key="el.header" class="">
          <p class="tag">
            {{ el.header }}
          </p>
          <ul class="gap-3">
            <li v-for="(item, idx) in el.links" :key="idx">
              <NuxtLink
                :to="item.link"
                class="text-base text-grey-500 no-underline hover:text-grey-900"
                >{{ item.name }}</NuxtLink
              >
            </li>
          </ul>
          <div v-if="index == data.length - 1" class="mt-8">
            <p class="tag">Connect with us</p>
            <SocialButtons
              :data="socialData"
              hoz-align="start"
              icon-gap="gap-6"
            />
          </div>
        </div>
      </div>

      <div class="border-t border-grey-200 px-6 text-grey-500 md:px-8">
        <div class="pb-16 pt-8">
          <div class="">
            <div class="mb-4 text-center">
              <img
                src="/img/logos/acknowledgement-of-country.svg"
                alt=""
                class="inline-block h-8 w-8"
              />
            </div>
            <p class="m-0 mx-auto max-w-3xl text-center text-sm">
              We acknowledge the original communicators, connectors, and carers
              of the land and waters across Australia, the Aboriginal and Torres
              Strait Islander peoples. We pay our respects to Elders past,
              present, and emerging.
            </p>
            <p class="mx-auto mt-4 text-center text-sm">
              ©{{ year }} Aussie Broadband Limited. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup lang="ts">
const year = new Date().getFullYear();

const data = [
  {
    header: "About",
    links: [
      {
        name: "About Us",
        link: "/about-us/",
      },
      {
        name: "Community Impact",
        link: "/about-us/community/",
      },
      {
        name: "Sponsorship",
        link: "/about-us/community/helping-communities-connect/",
      },
      {
        name: "Blog",
        link: "/blog/",
      },
    ],
  },
  {
    header: "Help and Support",
    links: [
      {
        name: "Contact Us",
        link: "/contact/",
      },
      {
        name: "Help Centre",
        link: "/help-centre/",
      },
      {
        name: "Speed Test",
        link: "https://www.aussiebroadband.com.au/speed-test/",
      },
      {
        name: "Refer a Friend",
        link: "/refer-a-friend/",
      },
      {
        name: "Activate SIM",
        link: "https://www.aussiebroadband.com.au/tools/activate-sim/",
      },
      {
        name: "Network Centre",
        link: "/network/",
      },
      {
        name: "Disaster Support",
        link: "/about-us/community/disaster-support/",
      },
    ],
  },
  {
    header: "Policies and Legal",
    links: [
      {
        name: "Terms and Conditions",
        link: "/legal/#termsAndConditions",
      },
      {
        name: "Privacy Policy",
        link: "/legal/privacy-policy/",
      },
      {
        name: "Critical Information Summaries",
        link: "/legal/#criticalInfo",
      },
      {
        name: "Financial Hardship - Payment Assistance",
        link: "/legal/#financialHardships",
      },
      {
        name: "Legal",
        link: "/legal/",
      },
      {
        name: "Accessibility",
        link: "/accessibility/",
      },
      {
        name: "Complaints Handling",
        link: "/contact/complaint/",
      },
    ],
  },
  {
    header: "Company",
    links: [
      {
        name: "Investor Centre",
        link: "/investor-centre/",
      },
      {
        name: "Careers",
        link: "/careers/",
      },
      {
        name: "Media Centre",
        link: "/media-centre/",
      },
    ],
  },
];
const socialData = {
  facebook: {
    social: true,
  },
  youtube: {
    social: true,
  },
  instagram: {
    social: true,
  },
  twitter: {
    social: true,
  },
  linkedin: {
    social: true,
  },
};
</script>
